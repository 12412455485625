import { Link } from "gatsby";
import React from "react";
import { PROJECT_LINK } from "../../../../../preBuildScripts/misc/links";

import style from "./style/style.module.css";

const SubCategories = ({ childCats }) => {
  if (childCats.length > 0) {
    return (
      <div>
        {childCats.map(cat => {
          return (
            <div class="col-md-2 col-sm-3 col-xs-6">
              <div class={style.catImg}>
                <Link to={"/" + cat.URL}>
                  <img
                    src={PROJECT_LINK + "/store" + cat.image}
                    alt={cat.name + " Image"}
                  />
                </Link>
              </div>

              <div class="cat-desc-tl">
                <div class={style.catTitle}>
                  <Link to={"/" + cat.URL}>{cat.name}</Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

export default SubCategories;

import React, { useState, useEffect, useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import MoreShare from "./components/MoreShare.jsx";
import htmldecoder from "../../../../functions/htmldecoder";
import { runAfterSomeTime } from "../../../../functions/Utilities.js";
import { toggleCompare } from "../../../../header/Compare.jsx";
import {
  deleteCompareItem,
  toggleCompareAction,
  fetchComparedItemDetails,
  deleteComparedItemsDetails
} from "../../../../redux/actions/compareActions";

import { I18nContext, langCodeList } from "../../../../i18n";
import "./Styles/ItemCard.css";
import PriceTag from "../../../../shared/components/PriceTag/PriceTag";

import Modal from "@material-ui/core/Modal";
import DialogContent from "@material-ui/core/DialogContent";

import useCalculateDistance from "../../../../functions/useCalculateDistance";
import LocalModals from "../../../../shared/LocalModals/LocalModals";
import LazyloadImage from "./components/LazyLoadImage";
import { PROJECT_LINK } from "../../../../project-config.js";
import { navigate } from "gatsby";
import { Link } from "@reach/router";
import { FormattedNumber } from "react-intl";

const MORE_SHARE_TOGGLE_TIMEOUT = 100;

const ItemCard = props => {
  const { recently = false, supplier } = props;

  const dispatch = useDispatch();
  const { translate, currency } = useContext(I18nContext);
  const [
    showModal,
    setHandleClose,
    handleShowLocale,
    handleOpenLocationBar,
    productUrlHandler,
    supplierShippingType,
    urlState,
    rangeState
  ] = useCalculateDistance();

  const [productOnSale, setProductOnSale] = useState(
    false /* Math.round(Math.random()) */
  );

  const [moreActive, setMoreActive] = useState(false);

  const {
    id,
    title,
    code,
    desc,
    currency_sign,
    image,
    itemLargeImage,
    price,
    prices,
    url,
    properties
  } = props.itemCard;

  const [priceState, setPriceState] = useState({ price: null, quantity: null });

  useEffect(() => {
    if (prices && prices.length > 1) {
      if (prices[0].packagePrices && prices[0].packagePrices.length > 0) {
        const firstPackagePrices = prices.reduce((a, c) => {
          a.push(c.packagePrices[0]);
          return a;
        }, []);

        const cheapestPackagePrice = firstPackagePrices.sort((a, b) => {
          return a.price - b.price;
        });

        if (cheapestPackagePrice)
          setPriceState({
            price: cheapestPackagePrice[0].price,
            quantity: cheapestPackagePrice[0].quantity
          });
      } else {
        const firstPrice = prices[0];
        if (firstPrice)
          setPriceState({
            price: firstPrice.price_1
          });
      }
    } else if (prices && prices.length === 1) {
      if (prices[0].packagePrices && prices[0].packagePrices.length > 0) {
        const firstPackage = prices[0].packagePrices[0];
        if (firstPackage)
          setPriceState({
            price: firstPackage.price,
            quantity: firstPackage.quantity
          });
      } else {
        const firstPrice = prices[0];
        if (firstPrice)
          setPriceState({
            price: firstPrice.price_1
          });
      }
    }
  }, [prices]);

  const [productPromotion, setProductPromotion] = useState(
    properties && properties.PromoText
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const [favouriteState, setFavouriteState] = useState("favorite_border");
  const [compareIconState, setCompareIconState] = useState("");

  const compareListState = useSelector(
    state => state.compareListReducer.compareList,
    shallowEqual
  );

  const userLocationState = useSelector(
    state => state.geoLocationReducer.brandCompareUserLocation,
    shallowEqual
  );

  const userLocState = useSelector(
    state => state.userLocationReducer,
    shallowEqual
  );

  const brandsState = useSelector(
    state => state.geoLocationReducer.brands,
    shallowEqual
  );

  const distanceState = useSelector(
    state => state.categoryReducer.distance,
    shallowEqual
  );

  const navCategoryState = useSelector(
    state => state.menuReducer.navCategory,
    shallowEqual
  );

  let imageUrl = `${PROJECT_LINK}/${itemLargeImage}`;

  let isItemWishlisted = props.wishListState.some(w => w.id == id);
  let isItemCompared =
    compareListState && compareListState.some(i => i.id == id);

  const handleToggleWishlistIcon = (e, id) => {
    e.stopPropagation();
    isItemWishlisted
      ? setFavouriteState("favourite_border")
      : setFavouriteState("favourite");
    props.toggleWish(
      e,
      id,
      code,
      title,
      desc,
      currency_sign,
      image,
      price,
      url.replace("product/", "")
    );
  };

  const handleToggleCompareListIcon = (event, itemId) => {
    event.stopPropagation();
    let compareid = String(itemId);
    isItemCompared && compareIconState === ""
      ? setCompareIconState("-outlined")
      : setCompareIconState("");

    // if item's compare checkbox is not checked
    if (!isItemCompared) {
      dispatch(fetchComparedItemDetails(compareid));
    } else {
      dispatch(deleteComparedItemsDetails(compareid));
    }

    toggleCompare(
      //event,
      compareid,
      title,
      currency_sign,
      image,
      price,
      url,
      compareListState,
      isItemCompared,
      dispatch,
      deleteCompareItem,
      toggleCompareAction,
      translate
    );

    runAfterSomeTime(() => setMoreActive(false), MORE_SHARE_TOGGLE_TIMEOUT);
  };

  const renderWishlistIcon = () => {
    return (
      <i
        className={
          isItemWishlisted
            ? "no-select material-icons item-card-wishlist-icon active"
            : "no-select material-icons item-card-wishlist-icon"
        }
        id={id}
        onMouseEnter={() => setFavouriteState("favorite")}
        onMouseLeave={() => setFavouriteState("favorite_border")}
        onClick={e => {
          handleToggleWishlistIcon(e, id);
        }}
      >
        {isItemWishlisted || favouriteState === "favorite"
          ? `favorite`
          : `favorite_border`}
      </i>
    );
  };

  // const handleClickOnProductCard = (event, url) => {
  //   console.info(`URL NEW:${url}`, langCodeList);

  //   langCodeList.map(lang => {
  //     if (url.substring(0, 5).includes(`${lang}/`)) {
  //       url = url.replace(`${lang}`, "");
  //     }
  //   });

  //   if (url.includes("/product/")) {
  //     url = url.replace("/product/", "");
  //   } else {
  //     url = url.replace("product/", "");
  //   }
  //   const shouldIncludeSlash = url => {
  //     if (url.charAt(0) == "/") {
  //       return "";
  //     } else {
  //       return "/";
  //     }
  //   };

  //   /* setShippingTypeState(shippingType);
  //     setData(supplier, shippingType); */
  //   navigate(`${shouldIncludeSlash(url)}${url}`);
  //   //productUrlHandler(id, [], `${shouldIncludeSlash(url)}${url}`);
  // };

  const finalUrl = url => {
    // console.info(`URL NEW:${url}`, langCodeList);

    langCodeList.map(lang => {
      if (url.substring(0, 5).includes(`${lang}/`)) {
        url = url.replace(`${lang}`, "");
      }
    });

    if (url.includes("/product/")) {
      url = url.replace("/product/", "");
    } else {
      url = url.replace("product/", "");
    }
    const shouldIncludeSlash = url => {
      if (url.charAt(0) == "/") {
        return "";
      } else {
        return "/";
      }
    };

    return shouldIncludeSlash(url) + url;
    /* setShippingTypeState(shippingType);
      setData(supplier, shippingType); */
    navigate(`${shouldIncludeSlash(url)}${url}`);
    //productUrlHandler(id, [], `${shouldIncludeSlash(url)}${url}`);
  };

  const renderCompareIcon = () => {
    return (
      <React.Fragment>
        <i
          onMouseEnter={() => setCompareIconState("-outlined")}
          onMouseLeave={() => setCompareIconState("")}
          onClick={event => handleToggleCompareListIcon(event, id)}
          className={
            isItemCompared
              ? `no-select material-icons${compareIconState} compare-icon active`
              : `no-select material-icons${compareIconState} compare-icon`
          }
        >
          {isItemCompared || compareIconState === "-outlined"
            ? `check_box`
            : `check_box_outline_blank`}
        </i>
        <span
          onClick={event => handleToggleCompareListIcon(event, id)}
          className="compare-icon-tooltip"
        >
          {translate("itemCard.compareTooltip")}
        </span>
      </React.Fragment>
    );
  };

  const renderPromotion = () => {
    if (productPromotion)
      return (
        <div className="item-card-promotion-container">
          <span className="item-card-promotion--promo">{productPromotion}</span>{" "}
        </div>
      );
    else return null;
  };

  const renderSale = () => {
    if (productOnSale)
      return (
        <div className="item-card-sale-container">
          <span className="item-card-sale--sale">50% OFF</span>{" "}
        </div>
      );
    else return null;
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={setHandleClose}
        onEscapeKeyDown={setHandleClose}
      >
        <DialogContent className="locationbox-wrapper" id="getLocationDiv">
          {supplierShippingType !== "" ? (
            <LocalModals
              setHandleClose={setHandleClose}
              handleShowLocale={handleShowLocale}
              handleOpenLocationBar={handleOpenLocationBar}
              shippingTypeState={supplierShippingType}
              range={rangeState || 200}
              url={urlState}
            />
          ) : null}
        </DialogContent>
      </Modal>

      <div
        tabIndex="0"
        className={`home-item--wrapper${isMobileState ? ` mobile` : ``}${
          moreActive ? ` more-active` : ``
        }`}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          navigate(finalUrl(url));
        }}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            e.preventDefault();
            navigate(finalUrl(url));
          }
        }}
      >
        {!recently ? (
          <div className={`itemCard-buttons-container`}>
            <MoreShare
              id={id}
              moreActive={moreActive}
              setMoreActive={setMoreActive}
              compareClicked={handleToggleCompareListIcon}
              wishlistClicked={handleToggleWishlistIcon}
              url={url}
              title={title}
              imageUrl={imageUrl}
              isItemWishlisted={props.wishListState.some(w => w.id == id)}
            />
          </div>
        ) : null}
        <div
          className={`home-item${isMobileState ? ` mobile` : ``}${
            moreActive ? ` more-active` : ``
          }${productPromotion ? ` promo-item` : ``}`}
          style={{ cursor: "pointer" }}
        >
          {isItemWishlisted ? renderWishlistIcon() : null}
          {renderCompareIcon()}

          <div className={`item-card-image${isMobileState ? ` mobile` : ``}`}>
            <figure className="item-card-figure">
              <img
                className="figure"
                src={imageUrl}
                alt={`${htmldecoder(title)} Image`}
              />
              {/* <LazyloadImage
                classFun="figure"
                src={imageUrl}
                widthPx={200}
                srcsetSizes={[
                  { imageWidth: 200, viewPortWidth: 992 },
                  { imageWidth: 340, viewPortWidth: 768 },
                  { imageWidth: 170, viewPortWidth: 500 }
                ]}
                alt={htmldecoder(title)}
              /> */}
            </figure>
            {renderPromotion()}
            {renderSale()}
          </div>

          <div className={`item-card-regular${isMobileState ? ` mobile` : ``}`}>
            <div
              className="product-title"
              dangerouslySetInnerHTML={{
                __html: `<p>${title}</p }><p style="font-weight: 400; font-size: 0.9em">${code}</p>`
              }}
            ></div>

            <div
              className={`product-price${productOnSale ? " price-deal" : ""}`}
            >
              <div className="product-price--main">
                {priceState.quantity ? (
                  <div className="product-price--qtyAndPrice">
                    <span style={{ fontWeight: 400 }}>Aussi bas que</span>&nbsp;
                    <span>
                      <FormattedNumber
                        style="currency"
                        currency={currency}
                        value={priceState.price}
                      />
                    </span>
                    {priceState.quantity > 1 ? ` / ${priceState.quantity}` : ""}
                  </div>
                ) : (
                  <div className="product-price--price">
                    <span style={{ fontWeight: 400 }}>Aussi bas que</span>&nbsp;
                    <span>
                      <FormattedNumber
                        style="currency"
                        currency={currency}
                        value={priceState.price}
                      />
                    </span>
                  </div>
                )}
              </div>
              {productOnSale &&
              price &&
              Object.values(price).includes("value") &&
              price.value ? (
                <div className="product-price--deal">
                  <PriceTag
                    value={{
                      integer:
                        price.type !== "empty"
                          ? Math.round(price.value.integer / 2)
                          : 0,
                      decimal: price.type !== "empty" ? price.value.decimal : 0
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemCard;

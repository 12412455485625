import React, { useContext, useLayoutEffect, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Loading from "../../AC-Loading/Loading.jsx";
import encodeConverter from "../../../functions/htmldecoder";
import Async from "react-code-splitting";
import Grid from "@material-ui/core/Grid";
import "../Styles/StoreCategoryHeader.css";
import banner from "../../../assets/img/banners/example-banner.jpg";
import StarIcon from "@material-ui/icons/Star";
import StarHalfIcon from "@material-ui/icons/StarHalf";

import fbLogo from "../../../assets/img/sellerFB.png";
import twLogo from "../../../assets/img/sellerTW.png";
import igLogo from "../../../assets/img/sellerIG.png";
import ptLogo from "../../../assets/img/sellerPT.png";

import HelmetSeo from "../../../shared/components/AC-Helmet/HelmetSeo";
import { navigate } from "@reach/router";
import { I18nContext } from "../../../i18n";
import { handleReplaceImagesWithLargeImagesOnError } from "../../../functions/Utilities.js";
import { setGoBackToCategoryFromProductPage } from "../../../redux/actions/categoryActions.js";

const CategoryBreadcrumb = () => (
  <Async load={import("../../AC-Breadcrumb/CategoryBreadcrumb.jsx")} />
);

export default function StoreCategoryHeaderStore({
  data,
  storeSellerData,
  pickupLocData,
  supplierShippingInfo
}) {
  console.info("DATA", pickupLocData, supplierShippingInfo);

  const dispatch = useDispatch();
  const categoryNameState = data.name;
  const { translate } = useContext(I18nContext);
  const facetFilter = useSelector(
    state => state.facetReducer.filterUrl,
    shallowEqual
  );

  const storesState = useSelector(
    state => state.storeReducer.stores,
    shallowEqual
  );

  const categoryImageState = useSelector(
    state => state.categoryReducer.catImage,
    shallowEqual
  );

  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );

  const selectedStoreState = useSelector(
    state => state.storeReducer.selectedStore,
    shallowEqual
  );

  const cidN = data.cid;

  const handleAboutTabs = newTabID => {
    var tabs = document.getElementsByClassName("aboutTab");
    var tabBodies = document.getElementsByClassName("tabBody");

    for (var x = 0; x < tabs.length; x++) {
      tabs[x].classList.remove("activeTab");
    }
    for (var x = 0; x < tabBodies.length; x++) {
      tabBodies[x].classList.add("hidden");
    }

    var newTab = document.getElementById(newTabID + "Tab");
    var newTabBody = document.getElementById(newTabID);

    newTab.classList.add("activeTab");
    newTabBody.classList.remove("hidden");
  };

  useEffect(() => {
    dispatch(setGoBackToCategoryFromProductPage(true));
    return () => {
      setGoBackToCategoryFromProductPage(false);
    };
  }, []);

  useLayoutEffect(() => {
    if (storeSellerData) {
      console.info("storeSellerData", storeSellerData);

      if (pickupLocData && pickupLocData.length > 0) {
        var pickUpDiv = document.getElementById("pickUpData");
        pickUpDiv.innerHTML = "";

        for (var x = 0; x < pickupLocData.length; x++) {
          var tempHTML =
            "<p class='pickupDataBox'><span>" +
            pickupLocData[x].pickup_location_name +
            "</span><br/>" +
            pickupLocData[x].address_place +
            " - <a target='_blank' href='https://www.google.com/maps/dir/here/" +
            pickupLocData[x].latitude +
            "," +
            pickupLocData[x].longitude +
            "'>Get Directions</a>";

          if (pickupLocData[x].time != null) {
            tempHTML =
              tempHTML + "<br/><span>Hours:</span> " + pickupLocData[x].time;
          }

          if (pickupLocData[x].additional_information != null) {
            tempHTML =
              tempHTML +
              "<br/><span>Additional Info:</span> " +
              pickupLocData[x].additional_information;
          }

          tempHTML = tempHTML + "</p>";

          pickUpDiv.innerHTML = pickUpDiv.innerHTML + tempHTML;
        }
      } else {
        var pickupTab = document.getElementById("pickupInfoTab");
        pickupTab.classList.add("hidden");
      }

      if (supplierShippingInfo && supplierShippingInfo.length > 0) {
        var shippingDiv = document.getElementById("shippingData");
        shippingDiv.innerHTML = "";

        var tempHTML = "<p><span>Ships:</span> ";

        if (supplierShippingInfo[0].shipping_type == 0) {
          var shippingTab = document.getElementById("shippingInfoTab");
          shippingTab.classList.add("hidden");
        } else if (supplierShippingInfo[0].shipping_type == 1) {
          tempHTML = tempHTML + "Worldwide";
        } else if (supplierShippingInfo[0].shipping_type == 2) {
          tempHTML = tempHTML + "Within Canada";
        } else if (supplierShippingInfo[0].shipping_type == 3) {
          if (storeSellerData.province) {
            tempHTML = tempHTML + "Within " + storeSellerData.province;
          } else {
            tempHTML = tempHTML + "Within Province";
          }
        } else if (supplierShippingInfo[0].shipping_type == 4) {
          tempHTML =
            tempHTML + "Within " + supplierShippingInfo[0].range + "KM";
        }

        tempHTML = tempHTML + "</p>";

        if (
          supplierShippingInfo[0].instructions &&
          supplierShippingInfo[0].instructions != null
        ) {
          tempHTML =
            tempHTML +
            "<p><span>Additional Info:</span> " +
            supplierShippingInfo[0].instructions +
            "</p>";
        }

        shippingDiv.innerHTML = tempHTML;
      } else {
        var shippingTab = document.getElementById("shippingInfoTab");
        shippingTab.classList.add("hidden");
      }
    }
  }, []);

  let imageUrl =
    data.image.replace("images", "largeimages") +
    `?tr=ar-1-1,dpr-2,pr-true,f-auto,w-150`;
  console.info("IMAGE KIT", imageUrl);
  if (imageUrl.includes("sls3.avetti.ca")) {
    imageUrl = imageUrl.replace(
      "sls3.avetti.ca",
      "demob2b2cs3.avetti.ca/store"
    );
  }

  var mapsLink;
  if (
    storeSellerData &&
    Object.keys(storeSellerData).includes("location") &&
    storeSellerData.location
  ) {
    mapsLink = "https://www.google.ca/maps/place/" + storeSellerData.location;
  } else {
    mapsLink = "";
  }

  return (
    <React.Fragment>
      <div className="myContainer">
        <div className="storeInfoWrapper">
          <div className="storeLogo">
            <img
              src={imageUrl}
              onError={handleReplaceImagesWithLargeImagesOnError}
            ></img>
          </div>
          <div className="storeInfoMain">
            <div className="storeInfo1">
              <h1 className="storeName testClass">{categoryNameState}</h1>
              {storeSellerData &&
              Object.keys(storeSellerData).includes("year") &&
              storeSellerData.year != "" ? (
                <p>
                  <span>
                    Established{" "}
                    {storeSellerData &&
                      Object.keys(storeSellerData).includes("year") &&
                      storeSellerData.year}{" "}
                    -{" "}
                    {storeSellerData &&
                      Object.keys(storeSellerData).includes("city") &&
                      storeSellerData.city}
                    ,{" "}
                    {storeSellerData &&
                      Object.keys(storeSellerData).includes("province") &&
                      storeSellerData.province}
                  </span>
                </p>
              ) : null}

              {storeSellerData &&
              Object.keys(storeSellerData).includes("phone_number") &&
              storeSellerData.phone_number != "" ? (
                <p>
                  <span>Phone:</span>{" "}
                  {storeSellerData &&
                    Object.keys(storeSellerData).includes("phone_number") &&
                    storeSellerData.phone_number}
                </p>
              ) : null}

              {storeSellerData &&
              Object.keys(storeSellerData).includes("location") &&
              storeSellerData.location != "" ? (
                <p>
                  <span>Address:</span>{" "}
                  {storeSellerData &&
                    Object.keys(storeSellerData).includes("location") &&
                    storeSellerData.location}
                  <br />
                  {mapsLink != "" ? (
                    <a className="mapsLink" target="_blank" href={mapsLink}>
                      View on Google Maps
                    </a>
                  ) : null}
                </p>
              ) : null}

              {storeSellerData &&
              Object.keys(storeSellerData).includes("website") &&
              storeSellerData.website != null ? (
                <p>
                  <span>Website:</span> {storeSellerData.website}
                </p>
              ) : null}
              <p className="sellerSocialLine">
                {storeSellerData !== undefined &&
                (Object.keys(storeSellerData).includes("facebook") ||
                  Object.keys(storeSellerData).includes("twitter") ||
                  Object.keys(storeSellerData).includes("instagram") ||
                  Object.keys(storeSellerData).includes("pinterest")) &&
                (storeSellerData.facebook != null ||
                  storeSellerData.twitter != null ||
                  storeSellerData.instagram != null ||
                  storeSellerData.pinterest != null) ? (
                  <span>
                    <span>Connect With Us:</span>
                    <br />
                  </span>
                ) : null}

                {storeSellerData && storeSellerData.facebook ? (
                  <a href={storeSellerData.facebook}>
                    <img className="sellerSocialLogo" src={fbLogo} />
                  </a>
                ) : null}
                {storeSellerData && storeSellerData.twitter ? (
                  <a href={storeSellerData.twitter}>
                    <img className="sellerSocialLogo" src={twLogo} />
                  </a>
                ) : null}
                {storeSellerData && storeSellerData.instagram ? (
                  <a href={storeSellerData.instagram}>
                    <img className="sellerSocialLogo" src={igLogo} />
                  </a>
                ) : null}
                {storeSellerData && storeSellerData.pinterest ? (
                  <a href={storeSellerData.pinterest}>
                    <img className="sellerSocialLogo" src={ptLogo} />
                  </a>
                ) : null}
              </p>
            </div>
            {storeSellerData && (
              <div className="storeInfo2 storeHeader">
                <div className="aboutTabs">
                  <h2
                    id="aboutInfoTab"
                    className="storeName aboutTab activeTab"
                    onClick={() => {
                      handleAboutTabs("aboutInfo");
                    }}
                  >
                    About Us
                  </h2>
                  <h2
                    id="pickupInfoTab"
                    className="storeName aboutTab"
                    onClick={() => {
                      handleAboutTabs("pickupInfo");
                    }}
                  >
                    Pickup Info
                  </h2>
                  <h2
                    id="shippingInfoTab"
                    className="storeName aboutTab"
                    onClick={() => {
                      handleAboutTabs("shippingInfo");
                    }}
                  >
                    Shipping Info
                  </h2>
                </div>

                <div id="aboutInfo" className="tabBody">
                  <p>
                    {storeSellerData &&
                      storeSellerData.description &&
                      storeSellerData.description.blocks &&
                      storeSellerData.description.blocks[0] &&
                      storeSellerData.description.blocks[0].text}
                  </p>
                </div>
                <div id="pickupInfo" className="tabBody hidden">
                  <div id="pickUpData"></div>
                </div>
                <div id="shippingInfo" className="tabBody hidden">
                  <div id="shippingData"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

import React from "react";

import encodeConverter from "../../../../functions/htmldecoder";
import Grid from "@material-ui/core/Grid";

import { PROJECT_LINK } from "../../../../project-config";
import { useLocation } from "@reach/router";
import CategoryBreadcrumb from "../../../AC-Breadcrumb/CategoryBreadcrumb.jsx";
import { htmlDecode } from "../../../../functions/htmldecoder";

import classes from "../CategoryHeader/Styles/BDCategoryHeader.module.css";

export default function CategoryHeader({ data }) {
  const location = useLocation();
  const categoryImageState = data.image;
  console.info("borop data", data);

  return (
    <React.Fragment>
      <div
        className="sub-nav-wrapper"
        style={{
          background: `url(https://ik.imagekit.io/ofb/store${categoryImageState}?tr=pr-true,f-auto)`
        }}
      >
        <div className="sub-nav-menu">
          <div className="sub-nav-title-desc-wrapper">
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={12}>
                <h1
                  style={{ backgroundColor: "transparent" }}
                  className="sub-nav-menu-title"
                  dangerouslySetInnerHTML={{
                    __html: data.description.replace(/&amp;/gm, "&")
                  }}
                ></h1>
              </Grid>
            </Grid>
          </div>
        </div>
        <CategoryBreadcrumb />
      </div>
    </React.Fragment>
  );
}

import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useDispatch } from "react-redux";

import {
  dispatchCurrentPage,
  nextPage
} from "../../redux/actions/paginationActions.js";

import { I18nContext } from "../../i18n/index";
import classes from "./Styles/BDPagination.module.css";

export default function Pagination({ data }) {
  const dispatch = useDispatch();
  const { translate } = React.useContext(I18nContext);

  const pagesState = useSelector(
    state => state.categoryReducer.pages,
    shallowEqual
  );
  const currentPageState = useSelector(
    state => state.categoryReducer.currentPage,
    shallowEqual
  );
  const scroolPageState = useSelector(
    state => state.categoryReducer.scroolPage,
    shallowEqual
  );

  const cidState = data.cid;

  const loadingState = useSelector(
    state => state.categoryReducer.loading,
    shallowEqual
  );

  const renderFirstAndLastButtons = (
    <React.Fragment>
      <span
        className={`${classes.paginationBtn} "first buttonPageLeft"`}
        onClick={() => handleNextPage(1)}
      >
        {translate("js.category.pagination.first")}
      </span>
      <span
        className={`${classes.paginationBtn} "prev"`}
        onClick={() => handleNextPage(currentPageState - 1)}
      >
        <i className="material-icons view-more-icon">keyboard_arrow_left</i>
      </span>
    </React.Fragment>
  );

  const handleNextPage = page => {
    if (scroolPageState < pagesState.length || page != 0) {
      dispatch(dispatchCurrentPage(page));
      dispatch(nextPage(page, cidState, false));
    } else {
      console.info("End of the page");
    }
  };

  return (
    <React.Fragment>
      {!loadingState && pagesState.length > 1 ? (
        <div
          style={{ float: "right", width: "auto", fontSize: "0.9375em" }}
          className="pagination-container"
        >
          <div id="pagination" className={classes.pagination}>
            {currentPageState > 1 ? renderFirstAndLastButtons : null}

            {pagesState.map(page => {
              if (
                page <= currentPageState + 5 &&
                page >= currentPageState - 5
              ) {
                return (
                  <span
                    key={page}
                    className={`${classes.paginationBtn} ${
                      currentPageState == page ? classes.selected : ""
                    }`}
                    onClick={() => handleNextPage(page)}
                    style={
                      currentPageState == 1 && page == 1
                        ? { borderLeft: "unset" }
                        : null
                    }
                  >
                    {page}
                  </span>
                );
              }
            })}

            {currentPageState == pagesState[pagesState.length - 1] ? null : (
              <React.Fragment>
                <span
                  className={`${classes.paginationBtn} next`}
                  onClick={() => handleNextPage(currentPageState + 1)}
                >
                  <i className="material-icons view-more-icon">
                    keyboard_arrow_right
                  </i>
                </span>
                <span
                  className={`${classes.paginationBtn} ${classes.last}`}
                  onClick={() => handleNextPage(pagesState.length)}
                >
                  {translate("js.category.pagination.last")}
                </span>
              </React.Fragment>
            )}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

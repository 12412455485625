import React from "react";
import { graphql } from "gatsby";
import Layout from "../other/layout";
import CategoryPage from "../components/OFB-Category/Category";
import CategorySellersPage from "../components/AC-Stores/StoresGatsby";

import SEO from "../other/seo";
import { PROJECT_LINK, SOLE_PROJECT_LINK } from "../project-config";

export const query = graphql`
  query MyQuery2($url: [String]) {
    allAvettiMenu {
      edges {
        node {
          name
          childs {
            name
            cid
            URL
            description
            image

            position
            thumbnail
            vid
            properties {
              propname
              propvalue
            }
          }
        }
      }
    }
    allAvettiCategory(filter: { url: { in: $url } }) {
      nodes {
        cid
        url
        name
        image
        itemsCount
        numberOfPages
        description
        itemsFirstPage {
          id
          title
          code
          desc
          currency_sign
          image
          itemLargeImage
          price {
            value {
              integer
              decimal
            }
          }
          prices {
            packagePrices {
              price
              quantity
            }
            itemcode
            priceLabel
            price_1
          }
          url
          properties {
            Sys_Package_Price_Enabled
          }
        }

        childCats {
          cid
          name
          position
          image
          URL
        }
        otherPages {
          _2 {
            id
            title
            code
            desc
            currency_sign
            image
            itemLargeImage
            price {
              value {
                integer
                decimal
              }
            }
            prices {
              packagePrices {
                price
                quantity
              }
              price_1
              itemcode
              priceLabel
            }
            url
          }
        }
        childCats {
          cid
          name
          position
          image
          URL
        }
      }
    }
  }
`;
const Category = ({ data, pageContext }) => {
  const name = data.allAvettiCategory.nodes[0].name;
  const image = data.allAvettiCategory.nodes[0].image;
  // console.info("DATA HERE", data.allAvettiCategory.nodes[0]);
  const imageUrl = `${PROJECT_LINK}/store/${image}`;
  const navCategory = data.allAvettiMenu.edges[0].node.childs.filter(
    chil =>
      chil.URL.replace("shop/", "") === data.allAvettiCategory.nodes[0].url
  )[0];

  return (
    <Layout>
      <SEO
        description={""}
        lang={"fr"}
        title={name.replace(/&amp;/gm, "&")}
        pathname={data.allAvettiCategory.nodes[0].url}
        meta={[
          {
            name: `og:image`,
            content: imageUrl
          },
          {
            name: `og:image:secure_url`,
            content: imageUrl
          },
          {
            name: `twitter:image`,
            content: imageUrl
          },
          {
            name: `og:url`,
            content: `${SOLE_PROJECT_LINK}/${data.allAvettiCategory.nodes[0].url}`
          }
        ]}
      />
      {data.allAvettiCategory.nodes[0].name === "Sellers" ? null : (
        <CategoryPage
          data={data.allAvettiCategory.nodes[0]}
          pageContext={pageContext}
          navCategory={navCategory}
          supplier={[]}
        />
      )}
    </Layout>
  );
};

export default Category;
